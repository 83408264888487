export enum PaymentMethodStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  PendingActivation = 'PENDING_ACTIVATION',
  Pending = 'PENDING',
  Inactive = 'INACTIVE',
}

export enum CardStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Inactive = 'INACTIVE',
  PendingIssue = 'PENDING_ISSUE',
  PendingRenewal = 'PENDING_RENEWAL',
  PendingReissue = 'PENDING_REISSUE',
  PendingPinSet = 'PENDING_PIN_SET',
  PendingActivation = 'PENDING_ACTIVATION',
  Lost = 'LOST',
  Stolen = 'STOLEN',
  Expired = 'EXPIRED',
  PendingExpiration = 'PENDING_EXPIRATION',
}

export enum ExternalEntityTypes {
  Organisation = 'ORGANISATION',
  OrganisationGroup = 'ORGANISATION_GROUP',
  OrganisationStaff = 'ORGANISATION_STAFF',
  Passenger = 'PASSENGER',
}

export enum LocationModelTypes {
  Boundary = 'BOUNDARY',
  Radius = 'RADIUS',
  Airport = 'AIRPORT',
  Suburb = 'SUBURB',
}

export enum LocationStatus {
  EN_ROUTE,
  APPROACHING,
  ARRIVED,
  DEPARTED,
  SKIPPED,
}

export enum RulesetStatusModelTypes {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum RuleModelTypes {
  TransactionLimit = 'TRANSACTION_LIMIT',
  TimeOfDay = 'TIME_OF_DAY',
  TripReason = 'TRIP_REASON',
  Boundary = 'BOUNDARY',
  Airport = 'AIRPORT',
  Suburb = 'SUBURB',
  Radius = 'RADIUS',
}

export enum ViolationModelTypes {
  Ok = 'OK',
  Audit = 'AUDIT',
  Warn = 'WARN',
  Split = 'SPLIT',
  Decline = 'DECLINE',
}

export enum BondaryModelTypes {
  Airport = 'AIRPORT',
  EBA = 'EBA',
}

export enum RouteRestrictionModelTypes {
  Start = 'START',
  End = 'END',
  StartOrEnd = 'START_OR_END',
  StartAndEnd = 'START_AND_END',
}

export enum TransactionModelTypes {
  Booking = 'BOOKING',
  Payment = 'PAYMENT',
  Any = 'ANY',
}

export enum PaymentMethodTypes {
  IngogoBusinessCard = 'INGOGO_BUSINESS_MASTERCARD',
  OrganisationAccount = 'ORGANISATION_ACCOUNT',

  TravellerCredits = 'TRAVELLER_CREDITS',
  StoredCard = 'STORED_CARD',
  DisbursementBankAccount = 'BANK_ACCOUNT',
}
export enum OrganisationStaffStatusTypes {
  Invited = 'INVITED',
  Pending_Approval = 'PENDING_APPROVAL',
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
}

export enum DriverStatus {
  New = 'NEW',
  Invited = 'INVITED',
  Incomplete = 'INCOMPLETE',
  Unqualified = 'UNQUALIFIED',
  PendingReview = 'PENDING_REVIEW',
  InReview = 'IN_REVIEW',
  ModelRequired = 'INFO_REQUIRED',
  Approved = 'APPROVED',
  PendingActivation = 'PENDING_ACTIVATION',
  Active = 'ACTIVE',
  OnLeave = 'ON_LEAVE',
  Dormant = 'DORMANT',
  Lost = 'LOST',
  Churned = 'Churned',
  Resurrected = 'RESURRECTED',
  Suspend = 'SUSPENDED',
  Blacklisted = 'BLACKLISTED',
}

export enum DriverActionStatus {
  CLOSED = 'CLOSED',
  NEW = 'NEW',
  INVITED = 'INVITED',
  INCOMPLETE = 'INCOMPLETE',
  UNQUALIFIED = 'UNQUALIFIED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  IN_REVIEW = 'IN_REVIEW',
  INFO_REQUIRED = 'INFO_REQUIRED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  PENDING_CLOSURE = 'PENDING_CLOSURE',
  ACTIVE = 'ACTIVE',
  ON_LEAVE = 'ON_LEAVE',
  DORMANT = 'DORMANT',
  DELETED = 'DELETED',
  CHURNED = 'CHURNED',
  RESURRECTED = 'RESURRECTED',
  SUSPENDED = 'SUSPENDED',
  BANNED = 'BANNED',
}

export enum TravellerActionStatus {
  NEW = 'NEW',
  INVITED = 'INVITED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
  BANNED = 'BANNED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLLED',
  RESURRECTED = 'RESURRECTED',
  CHURNED = 'CHURNED',
  DORMANT = 'DORMANT',
  UNREGISTERED = 'UNREGISTERED',
}

export enum TravellerActionType {
  CREATE_TRAVELLER = 'CREATE_TRAVELLER',
  REGISTER_TRAVELLER = 'REGISTER_TRAVELLER',
  DELETE_TRAVELLER = 'DELETE_TRAVELLER',
  INVITE_TRAVELLER = 'INVITE_TRAVELLER',
  ACTIVATE_TRAVELLER = 'ACTIVATE_TRAVELLER',
  SUSPEND_TRAVELLER = 'SUSPEND_TRAVELLER',
  BAN_TRAVELLER = 'BAN_TRAVELLER',
  RESURRECT_TRAVELLER = 'RESURRECT_TRAVELLER',
  DISABLE_TRAVELLER = 'DISABLE_TRAVELLER',
}

export enum OperatorActionStatus {
  SUSPENDED = 'SUSPENDED',
  INCOMPLETE = 'INCOMPLETE',
  ACTIVE = 'ACTIVE',
}

export enum VehicleStatus {
  INCOMPLETE = 'INCOMPLETE',
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_INSTALLATION = 'PENDING_INSTALLATION',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  INACTIVE = 'INACTIVE',
  OFFROAD = 'OFFROAD',
  PENDING_UNINSTALLATION = 'PENDING_UNINSTALLATION',
  PENDING_CLOSURE = 'PENDING_CLOSURE',
  CLOSED = 'CLOSED',
  TRANSFERRED = 'TRANSFERRED',
}

export enum LifeCycleStage {
  Lead = 'LEAD',
  Opportunity = 'OPPORTUNITY',
  Customer = 'CUSTOMER',
  Other = 'OTHER',
}

export enum DocumentType {
  DriverLicenceFront = 'DRIVER_LICENCE_FRONT',
  DriverLicenceBack = 'DRIVER_LICENCE_BACK',
  TaxiAuthorityFront = 'TAXI_AUTHORITY_FRONT',
  TaxiAuthorityBack = 'TAXI_AUTHORITY_BACK',
  DriverPhoto = 'DRIVER_PHOTO',
  OrganisationStaffFile = 'ORGANISATION_STAFF_FILE',
}

export enum BookingStatus {
  Initial = 'INITIAL',
  Booked = 'BOOKED',
  AwaitingAllocation = 'AWAITING_ALLOCATION',
  Abandoned = 'ABANDONED',
  Allocated = 'ALLOCATED',
  EnRouteToPickup = 'EN_ROUTE_TO_PICKUP',
  AproachingPickup = 'APPROACHING_PICKUP',
  ArrivedAtPickup = 'ARRIVED_AT_PICKUP',
  EnRouteToDestination = 'EN_ROUTE_TO_DESTINATION',
  ApproachingDestination = 'APPROACHING_DESTINATION',
  PaymentDue = 'PAYMENT_DUE',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
}
export enum TravelPolicyStatus {
  Split = 'SPLIT',
  Error = 'ERROR',
  Warn = 'WARN',
}

export enum TravelPolicyRuleType {
  TransactionLimit = 'TRANSACTION_LIMIT',
  TimeOfDay = 'TIME_OF_DAY',
  TripReason = 'TRIP_REASON',
  Airport = 'AIRPORT',
  Boundary = 'BOUNDARY',
  Radius = 'RADIUS',
}

export enum PolicyDisplayArg {
  RuleModelTypeClass = 'RULE_MODEL_TYPE_CLASS',
}

// export enum RulesetSearchType {
//     Published = 'PUBLISHED',
//     Draft = 'DRAFT',
//     Archived = 'ARCHIVED'
// }

export enum ActionStatus {
  INCOMPLETE = 'INCOMPLETE',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
}

export enum ActionOutcome {
  PASS = 'PASS',
  FAIL = 'FAIL',
  NONE = 'NONE',
}

export enum ActionEditMode {
  NONE = 'NONE',
  REPLACE = 'REPLACE',
  VERIFY = 'VERIFY',
  INVALID = 'INVALID',
}

export enum ReviewStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
}

export enum EntityType {
  TRAVELLER = 'TRAVELLER',
  ORGANISATION = 'ORGANISATION',
  DRIVER = 'DRIVER',
  WEB = 'WEB',
  UNREGISTERED = 'UNREGISTERED',
}

export enum JobStatus {
  OPEN = 'OPEN',
  DISPATCHED = 'DISPATCHED',
  STARTED = 'STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ABANDONED = 'ABANDONED',
  ASSIGNED = 'ASSIGNED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  ACKNOWLEDGEMENT_REQUIRED = 'ACKNOWLEDGEMENT_REQUIRED',
}

export enum IssueType {
  ACKNOWLEDGE = 'ACKNOWLEDGE',
  INVALID = 'INVALID',
  REQUIRED = 'REQUIRED',
}

export enum IssueLevel {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}
export enum JobType {
  RANK_HAIL = 'RANK_HAIL',
  BOOKED = 'BOOKED',
  ON_DEMAND = 'ON_DEMAND',
  SCHEDULED = 'SCHEDULED',
}

export enum PlaceType {
  ADDRESS = 'ADDRESS',
  PLACE = 'PLACE',
  TRANSPORT = 'TRANSPORT',
  ACCOMMODATION = 'ACCOMODATION',
  FOOD_AND_DRINK = 'FOOD_AND_DRINK',
  AIRPORT = 'AIRPORT',
  ENTERTAINMENT = 'ENTERTAINMENT',
  ATTRACTION = 'ATTRACTION',
  BUSINESS = 'BUSINESS',
  FAVOURITE = 'FAVOURITE',
  UNKNOWN = 'UNKNOWN',
}

export enum AssetStatusType {
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  PENDING_ORDER = 'PENDING_ORDER',
  PENDING_COLLECTION = 'PENDING_COLLECTION',
  ORDERED = 'SENT',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',

  READY = 'READY',
  ACTIVE = 'ACTIVE',
  REPAIR_WARRANTY = 'REPAIR_WARRANTY',
  REPAIR_DAMAGED = 'REPAIR_DAMAGED',
  LOST_STOLEN = 'LOST_STOLEN',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
}

export enum AssetReturnReasonCode {
  REPAIR_WARRANTY = 'REPAIR_WARRANTY',
  REPAIR_DAMAGED = 'REPAIR_DAMAGED',
  LOST_STOLEN = 'LOST_STOLEN',
}

export enum AssetRepairReasonCode {
  WARRANTY = 'WARRANTY',
  DAMAGED = 'DAMAGED',
}

export enum AssetGroupType {
  STORAGE = 'STORAGE',
  PACK = 'PACK',
  SHIPMENT = 'SHIPMENT',
  ORDER = 'ORDER',
}

export enum OrderStatus {
  PENDING_VERIFICATION,
  PENDING_PAYMENT,
  PENDING_ORDER,
  PENDING_COLLECTION,
  SENT,
  PENDING_MANIFEST,
  INITIATED,
  DELIVERED,
  COLLECTED,
  IN_TRANSIT,
  AWAITING_COLLECTION,
  AUSPOST_GENERIC,
}

export type OrderStatusType =
  | 'PENDING_VERIFICATION'
  | 'PENDING_PAYMENT'
  | 'PENDING_ORDER'
  | 'PENDING_COLLECTION'
  | 'SENT'
  | 'PENDING_MANIFEST'
  | 'INITIATED'
  | 'DELIVERED'
  | 'COLLECTED'
  | 'IN_TRANSIT'
  | 'AWAITING_COLLECTION'
  | 'AUSPOST_GENERIC';

export enum AssetShareStatus {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NOT_SHARED = 'NOT_SHARED',
  SHARED = 'SHARED',
  ON_LOAN = 'ON_LOAN',
}

export enum TravellerStatus {
  UNREGISTERED = 'UNREGISTERED',
  INVITED = 'INVITED',
  NEW = 'NEW',
  INCOMPLETE = 'INCOMPLETE',
  UNQUALIFIED = 'UNQUALIFIED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  RESURRECTED = 'RESURRECTED',
  ACTIVE = 'ACTIVE',
  DORMANT = 'DORMANT',
  DELETED = 'DELETED',
  CHURNED = 'CHURNED',
  SUSPENDED = 'SUSPENDED',
  DISABLED = 'DISABLED',
  BANNED = 'BANNED',
}

export enum PassengerActions {
  CREATE_PASSENGER = 'CREATE_PASSENGER',
  DELETE_PASSENGER = 'DELETE_PASSENGER',
  INVITE_PASSENGER = 'INVITE_PASSENGER',
  ACTIVATE_PASSENGER = 'ACTIVATE_PASSENGER',
  SUSPEND_PASSENGER = 'SUSPEND_PASSENGER',
  BAN_PASSENGER = 'BAN_PASSENGER',
  RESURRECT_PASSENGER = 'RESURRECT_PASSENGER',
  DISABLE_PASSENGER = 'DISABLE_PASSENGER',
}

export enum NetworkType {
  PARTNER = 'PARTNER',
  PRIVATE = 'PRIVATE',
}

export enum FleetProductVariant {
  FLEET_PAY = 'FLEET_PAY',
  FLEET_BOOKING = 'FLEET_BOOKING',
  FLEET_STANDARD = 'FLEET_STANDARD',
}

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  OUTSTANDING = 'OUTSTANDING',
  PAID = 'PAID',
  VOID = 'VOID',
  PENDING_DEBT_COLLECTION = 'PENDING_DEBT_COLLECTION',
  WRITTEN_OFF = 'WRITTEN_OFF',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum DriverActions {
  INVITE_DRIVER = 'INVITE_DRIVER',
  CREATE_DRIVER = 'CREATE_DRIVER',
  RESURRECT_DRIVER = 'RESURRECT_DRIVER',
  REVIEW_DRIVER = 'REVIEW_DRIVER',
  VERIFY_DRIVER = 'VERIFY_DRIVER',
  APPROVE_DRIVER = 'APPROVE_DRIVER',
  REJECT_DRIVER = 'REJECT_DRIVER',
  ACTIVATE_DRIVER = 'ACTIVATE_DRIVER',
  SUSPEND_DRIVER = 'SUSPEND_DRIVER',
  BAN_DRIVER = 'BAN_DRIVER',
  LOST_DRIVER = 'LOST_DRIVER',
}

export enum DriverSectionNames {
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  DRIVER_LICENCE = 'DRIVER_LICENCE',
  TAXI_AUTHORITY_DETAILS = 'TAXI_AUTHORITY_DETAILS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
}

export enum ReviewType {
  DRIVER = 'DRIVER',
  AML = 'AML',
  RETURN_AUTHORISATION = 'RETURN_AUTHORISATION',
  NETWORK = 'NETWORK',
}

export enum OrganisationUserStatus {
  PENDING_INVITATION = 'PENDING_INVITATION',
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum NetworkUserStatus {
  DRAFT = 'DRAFT',
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ServiceClassTypes {
  TAXI = 'TAXI',
  HIRE_CARD = 'HIRE_CAR',
  LOGISTICS = 'LOGISTICS',
  RIDESHARE = 'RIDESHARE',
}

export enum VehicleInsuranceType {
  CTP = 'Compulsory Third-Party',
  FIRE_THEFT = 'Fire and Theft',
  COMPREHENSIVE = 'Comprehensive',
}

export enum AlertType {
  EARLY_COMPLETION = 'EARLY_COMPLETION',
  PAYMENT_METHODS = 'PAYMENT_METHODS',
  DAILY_TRIP = 'DAILY_TRIP',
  MATCHED_BLOCKED_EMAIL = 'MATCHED_BLOCKED_EMAIL',
  MATCHED_BLOCKED_REGEX_EMAIL = 'MATCHED_BLOCKED_REGEX_EMAIL',
  MATCHED_BLOCKED_ADDRESS = 'MATCHED_BLOCKED_ADDRESS',
  MATCHED_BLOCKED_DEVICE = 'MATCHED_BLOCKED_DEVICE',
  INVALID_EMAIL = 'INVALID_EMAIL',
  STOLEN_CARD = 'STOLEN_CARD',
  RESTRICTED_CARD = 'RESTRICTED_CARD',
  LOST_CARD = 'LOST_CARD',
  PERIOD_TRIP = 'PERIOD_TRIP',
  DROP_OFF = 'DROP_OFF',
  ROUTE_ALTERATION = 'ROUTE_ALTERATION',
  SHARED_CARD = 'SHARED_CARD',
  FRAUDULENT_USAGE = 'FRAUDULENT_USAGE',
  FAILED_SECURITY_CHECK = 'FAILED_SECURITY_CHECK',
  AML_MATCH = 'AML_MATCH',
}

export enum NotificationType {
  TRANSACTIONAL = 'TRANSACTIONAL',
  BROADCAST = 'BROADCAST',
}
export enum ImportStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  IMPORTED = 'IMPORTED',
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
}

export enum FileImportStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  IMPORTING = 'IMPORTING',
  IMPORTED = 'IMPORTED',
  FAILED = 'FAILED',
}

export enum TransactionStatus {
  PROCESSED = 'PROCESSED',
  VOIDED = 'VOIDED',
  SETTLED = 'SETTLED',
  SETTLEMENT_REJECTED = 'SETTLEMENT_REJECTED',
  DIRECT_DEBITED = 'DIRECT_DEBITED',
  DIRECT_DEBIT_REJECTED = 'DIRECT_DEBIT_REJECTED',
}

export enum PaymentType {
  ORGANISATION_ACCOUNT = 'ORGANISATION_ACCOUNT',
  STORED_CARD = 'STORED_CARD',
  BUSINESS_MASTERCARD = 'BUSINESS_MASTERCARD',
  BUSINESS_MASTERCARD_ACCOUNT = 'BUSINESS_MASTERCARD_ACCOUNT',
  VOUCHER_ACCOUNT = 'VOUCHER_ACCOUNT',
  SPI = 'SPI',
  STANDALONE_PLUS = 'STANDALONE_PLUS',
  STANDALONE_TERMINAL = 'STANDALONE_TERMINAL',
  OTHER = 'OTHER',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  NETWORK_ACCOUNT = 'NETWORK_ACCOUNT',
  DEBT_COLLECTION = 'DEBT_COLLECTION',
  BANK_DEPOSIT = 'BANK_DEPOSIT',
  CASH = 'CASH',
}

export enum VerificationType {
  MOBILE_NUMBER = 'MOBILE_NUMBER',
  EMAIL = 'EMAIL',
  INVITATION = 'INVITATION',
  CREDIT_CARD_BRAINTREE = 'CREDIT_CARD_BRAINTREE', //Automatic credit card verification when a card is created on braintree
  CREDIT_CARD_MICRO_DEBIT = 'CREDIT_CARD_MICRO_DEBIT', //Micro debit credit card verification (usually for traveller cards)
  CREDIT_CARD_3DS = 'CREDIT_CARD_3DS', // 3ds verification
  NONE = 'NONE', // this is for cards added where configuration is such that the card does not need verification, e.g. organisation

  MFA_SOFTWARE_AUTHENTICATOR = 'MFA_SOFTWARE_AUTHENTICATOR', // support for 2FA software authenticator, e.g. Google Authenticator
  MFA_EMAIL = 'MFA_EMAIL', // support for 2FA verification code via email
  MFA_SMS = 'MFA_SMS', // support for 2FA verification code via SMS
}
