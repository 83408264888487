export const path = '/api/v3';

export const paths = {
  driver: path + '/driver',
  onboard: path + '/driver/onboard',
  learning: path + '/learning',
  course: path + '/course',
  document: path + '/document',
  admin: path + '/admin',
  validation: path + '/validation',
  asset: path + '/asset',
  organisation: path + '/organisation',
  reference: path + '/reference',
  traveller: path + '/traveller',
  applicationVersion: path + '/admin/applicationVersion',
  transaction: path + '/transaction',
  trip: path + '/trip',
  disbursement: path + '/disbursement',
  tasPolicy: '/policy',
  tasLocation: '/location',
  booking: path + '/traveller/booking',
  auth: path + '/auth',
  verify: path + '/verify',
  policy: path + '/policy',
  network: path + '/network',
  notification: path + '/notification',
  flight: path + '/flight',
  job: path + '/job',
  place: path + '/place',
  product: path + '/product',
  priceOption: path + '/priceOption',
  vehicle: path + '/vehicle',
  productConfiguration: path + '/brand',
  position: path + '/position',
  service: path + '/service',
  area: path + '/area',
  ticket: path + '/ticket',
  shift: path + '/shift',
  payment: path + '/payment',
  alert: path + '/alert',
  operator: path + '/operator',
  security: path + '/security',
  sale: path + '/sale',
  brand: path + '/brand',
  ivr: path + '/ivr',
  directDebit: path + '/directDebit',
  voucher: path + '/voucher',
  blocklist: path + '/blocklist',
  allowlist: path + '/allowlist',
  promotion: path + '/promotion',
  batch: path + '/batch',
  cabcharge: path + '/cabcharge/importer',
  hubspot: path + '/integration/hubspot',
  braintree: path + '/braintree',
  note: path + '/note',
  settings: path + '/setting',
  toll: path + '/toll',
  samlAuth: '/api/saml/idp/SSO/alias/fleet',
  stripe: path + '/stripe',
  testStoredcard: '/api/v3/test/storedcard',
  xero: path + '/integration/xero',
  quickbooks: path + '/integration/quickbooks',
  stripeIntegration: path + '/integration/stripe',
  everee: path + '/integration/everee',
  googleMaps: path + '/integration/googleMaps',
  braintreeIntegration: path + '/integration/braintree',
};
